define([
    'lodash',
    'react',
    'coreUtils',
    'prop-types',
    'santa-components',
    'wixFreemiumBanner/utils/translationUtils',
    'wixFreemiumBanner/css/wixCoBrandingAdsCss'
], function (_, React, coreUtils, PropTypes, santaComponents, translationsUtils, wixAdsCss) {
    'use strict';
    const createReactElement = santaComponents.utils.createReactElement;

    function getAdDesktopProps(href, wixAdsId, direction, overlay) {
        const classes = ['desktop-top'];

        if (overlay) {
            classes.push('overlay');
        }

        classes.push(direction);

        const props = {
            className: classes.join(' '),
            id: 'top',
            key: 'desktopTop',
            'data-aid': `${wixAdsId}desktopTop`
        };

        if (!overlay) {
            _.assign(props, {
                href,
                'target': '_blank',
                'rel': 'nofollow'
            });
        }
        return props;
    }

    function getAdMobileProps(href, wixAdsId, isHeaderFixedPosition, direction, overlay) {
        const classes = ['mobile-top'];

        if (overlay) {
            classes.push('overlay');
        }

        if (isHeaderFixedPosition) {
            classes.push('fixed-ad-mobile');
        }

        classes.push(direction);

        const props = {
            className: classes.join(' '),
            id: 'top',
            key: 'mobileTop',
            'data-aid': `${wixAdsId}mobileTop`
        };

        if (!overlay) {
            _.assign(props, {
                href,
                'target': '_blank',
                'rel': 'nofollow'
            });
        }
        return props;
    }

    function getBrandLogoString(brandLogoUrl) {
        return `<img src="${brandLogoUrl}" class="brand-logo"/>`;
    }

    function getBrandLogoElement(brandLogoUrl) {
        return createReactElement('img', {
            className: 'brand-logo',
            src: brandLogoUrl
        });
    }

    function getBrandFullText(bannerText, brandName, direction) {
        return createReactElement('div', {className: `text-container ${direction}`},
            createReactElement('span', {}, `${bannerText} `),
            createReactElement('span', {className: 'brand-name'}, brandName));
    }

    function getWixAdsElement(props) {
        const {
            wixAdsId,
            isMobileView,
            isHeaderFixedPosition,
            overlay,
            direction,
            brandName,
            brandSiteUrl,
            brandLogoUrl,
            translations
        } = props;

        if (overlay) {
            return createReactElement('div', isMobileView ?
                getAdMobileProps(null, wixAdsId, isHeaderFixedPosition, direction, overlay) :
                getAdDesktopProps(null, wixAdsId, direction, overlay));
        }

        const brandLogoString = getBrandLogoString(brandLogoUrl);
        const adsTranslation = translationsUtils.getWixCoBrandingTranslations(translations, brandLogoString, 'Wix_Ads_Co_Branding_Top_Banner', 'Wix_Ads_Mobile_Co_Branding_Top_Banner');

        return isMobileView ?
            createReactElement('a',
                getAdMobileProps(brandSiteUrl, wixAdsId, isHeaderFixedPosition, direction),
                adsTranslation.mobileBanner) :
            createReactElement('a',
                getAdDesktopProps(brandSiteUrl, wixAdsId, direction),
                createReactElement('div', {className: `logo-container ${direction}`}, getBrandLogoElement(brandLogoUrl)),
                getBrandFullText(adsTranslation.desktopBanner, brandName, direction)
            );
    }

    class WixCoBrandingBanner extends React.Component {
        render() {
            const {wixAdsId, isMobileView, isWixAdsAllowed} = this.props;

            const wrapperClasses = {
                'wix-co-branding-banner': true,
                visible: isWixAdsAllowed,
                hidden: !isWixAdsAllowed,
                mobile: isMobileView,
                desktop: !isMobileView
            };

            const wrapperProps = {
                ref: 'wrapper',
                id: wixAdsId,
                className: coreUtils.classNames(wrapperClasses)
            };

            const wrapperChildren = [
                santaComponents.utils.styleNodeUtils.generateStyleNode('wixAds-style', wixAdsCss)
            ];

            wrapperChildren.push(getWixAdsElement(this.props));

            return createReactElement('div', wrapperProps, wrapperChildren);
        }
    }
    WixCoBrandingBanner.displayName = 'WixCoBrandingBanner';
    WixCoBrandingBanner.propTypes = {
        wixAdsId: PropTypes.string,
        isMobileView: PropTypes.bool,
        isPreview: PropTypes.bool,
        isWixAdsAllowed: PropTypes.bool,
        language: PropTypes.string,
        metaSiteId: PropTypes.string,
        isHeaderFixedPosition: PropTypes.bool,
        translations: PropTypes.object,
        isWixAdsForOneApp: PropTypes.bool,
        reportBI: PropTypes.func,
        overlay: PropTypes.bool,
        direction: PropTypes.string,
        brandSiteUrl: PropTypes.string,
        brandName: PropTypes.string,
        brandLogoUrl: PropTypes.string
    };
    return WixCoBrandingBanner;
});
